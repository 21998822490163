<template>
    <div class="working-hours">        
        <div class="day-stripe">
            <div class="day-hours-heading">
                Day
            </div>
            
            <div class="hours-open-div">
                <div class="day-hour-time-heading hours-open-div-wrapper ">
                    Open
                </div>
            </div>
            <div class="close-hr hours-open-div">
                <div class="day-hour-time-heading hours-open-div-wrapper ">
                    Close
                </div>
            </div>
        </div>
        <div class="day-stripe" v-for="schedule in userSchedule" :key="schedule.id">
            <div class="day-hours-div">
                <label class="switch">
                    <input type="checkbox" v-model="schedule.isOpen" true-value="1" false-value="0">
                    <span class="slider round"></span>
                </label>
                <span class="ml-2">{{schedule.title}}</span>
            </div>
            <div class="hours-open-div">
                <div class="hours-open-div-wrapper">
                    <vue-timepicker v-model="schedule.start_time" :disabled="schedule.isOpen == 0" format="hh:mm A" :minute-interval="5" placeholder="hh:mm a"></vue-timepicker>
                    <!-- <Datepicker :clearable="false" v-model="schedule.start_time_formated" :disabled="schedule.isOpen == 0" :uid="'sch_start'+schedule.id" :autoApply="true" minutesIncrement="5" :timePicker="true" :is24="false" placeholder="hh:mm a" format="hh:mm aa"></Datepicker> -->
                </div>
            </div>
            <div class="close-hr hours-open-div">
                <div class="hours-open-div-wrapper pull-left">
                    <vue-timepicker v-model="schedule.end_time" :disabled="schedule.isOpen == 0" format="hh:mm A" :minute-interval="5" placeholder="hh:mm a"></vue-timepicker>
                    <!-- <Datepicker :clearable="false" v-model="schedule.end_time_formated" :disabled="schedule.isOpen == 0" :uid="'sch_end'+schedule.id" :autoApply="true" minutesIncrement="5" :timePicker="true" :is24="false" placeholder="hh:mm a" format="hh:mm aa"></Datepicker> -->
                </div>
            </div>
            <div class="pull-right time-error">
                <span v-show="schedule.hasError">{{schedule.errorMessage}}</span>
            </div>
        </div>
        <div class="row">
            <div class="mt-3 text-right col-sm-12">
                <button class="rem-btn pull-right" v-on:click="updateSchedule">Update</button>
            </div>
        </div>
    </div>
</template>

<script>
import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'
import axios from 'axios'
import { useToast } from "vue-toastification";
import $ from 'jquery';
import "jquery-datetimepicker/jquery.datetimepicker.js";
import "jquery-datetimepicker/jquery.datetimepicker.css";
import moment from 'moment'

export default {
    name: 'weeklySchedule',
    components: {
        VueTimepicker
    },
    setup () {
        const toast = useToast();
        return {toast}
    },
    data(){
		return{
            userSchedule:[],
            errorMsg : false
		}
    },
    created(){
        this.getSchedule()
	},
    methods:{
        getSchedule(){
            let thiss= this
            axios.get('business_hours/'+this.$storage.getStorageSync('business').active_business.id).then(function (response) {
                if (response.data.status == 'success') {
                    console.log(response.data.data)
                    thiss.userSchedule = response.data.data;
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.toast.error(error.response.data.message);
                    }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                        thiss.$storage.removeStorageSync("user")
                        thiss.$storage.removeStorageSync("token")
                        thiss.$storage.removeStorageSync("business")
                        thiss.$router.push({ name: 'Home'})
                    }
                }
            });
        },
        async updateSchedule(){
            //console.log(this.userSchedule)
            let hasError = false
            await this.userSchedule.forEach((value,index) => {
                if (value.isOpen == 1) {
                    if (value.start_time.hh != '' && value.start_time.hh != null && value.start_time.hh != undefined && value.start_time.hh != 'undefined' && value.end_time.hh != '' && value.end_time.hh != null && value.end_time.hh != undefined && value.end_time.hh != 'undefined') {
                        var open = value.start_time.hh+':'+value.start_time.mm+':'+value.start_time.A;
                        var close = value.end_time.hh+':'+value.end_time.mm+':'+value.end_time.A;
                        //convert both time into timestamp
                        var stt = new Date("November 13, 2019 " + open);
                        stt = stt.getTime();
                        var endt = new Date("November 13, 2019 " + close);
                        endt = endt.getTime();
                        if(stt >= endt) {
                            this.userSchedule[index].hasError = true
                            this.userSchedule[index].errorMessage = "Close time must be greater then Start time."
                            hasError = true
                        }else{
                            this.userSchedule[index].hasError = false                        
                        }
                    }else{
                        this.userSchedule[index].hasError = true
                        this.userSchedule[index].errorMessage = "Enter Open & Close Time."
                        hasError = true
                    }
                }
            });
            if (hasError === false) {
                this.updateScheduleProcess() 
            }
        },
        updateScheduleProcess(){
            let thiss = this
            axios.post('update_business_hours',this.userSchedule).then(function (response) {
                if (response.data.status == 'success') {
                    thiss.toast.success(response.data.message);
                }else if (response.data.status == 'login') {
                    thiss.$storage.removeStorageSync("user")
                    thiss.$storage.removeStorageSync("token")
                    thiss.$storage.removeStorageSync("business")
                    thiss.$router.push({ name: 'Home'})
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.toast.error(error.response.data.message);
                    }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                        thiss.$storage.removeStorageSync("user")
                        thiss.$storage.removeStorageSync("token")
                        thiss.$storage.removeStorageSync("business")
                        thiss.$router.push({ name: 'Home'})
                    }
                }
            });
        }
    },
    mounted() {
        //console.log(timepicker)
        window.jQuery.datetimepicker.setDateFormatter({
            parseDate: function (date, format) {
                var d = moment(date, format);
                return d.isValid() ? d.toDate() : false;
            },
            formatDate: function (date, format) {
                return moment(date).format(format);
            }
        });
        $('.datetimepicker').datetimepicker({
            datepicker:false,
            format:'h:mm A',
            formatTime:'h:mm A',
            todayButton: false,
            allowTimes:[
                '00:00','00:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30',
                '06:00','06:30','07:00','07:30','08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30',
                '12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30',
                '18:00','18:30','19:00','19:30','20:00','20:30','21:00','21:30','22:00','22:30','23:00','23:30'
            ]
        });
    }
}
</script>
<style >
.working-hours{
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(49, 81, 161, 0.08);
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 1.25rem;
}
.time-picker-overlay{
    outline: none;
}

.day-stripe{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-right:15px;padding-left:15px;-webkit-box-align:stretch;-webkit-align-items:stretch;-ms-flex-align:stretch;align-items:stretch;border-bottom:1px solid rgba(0,0,0,.1);-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap}

.day-hours-div,.day-hours-heading {
    width: 20%;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-right: 1px solid rgba(0, 0, 0, .1);
    font-size: 16px
}
.day-hours-div{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.day-hours-heading,.day-hour-time-heading{font-weight: 800;}
.day-hour-time-heading{
    padding: 10px 18px !important;
}
.day-hours-div label.vue-js-switch{
    margin-right: 15px;
}
.day-hours-div .v-switch-label{
    text-transform: uppercase;
}
.hours-open-div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20%;
    border-right: 1px solid rgba(0, 0, 0, .1)
}

.hours-open-div.close-hr {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: flex;
    border-right-style: none
}
.hours-open-div-wrapper{
    padding: 10px 12px;
    position: relative;
    display: flex;
    width: 170px;
    margin-bottom: 0;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.time-error{
    color: #eb6663;
    padding: 12px;
    display: flex;
    width: 40%;
    align-items: center;
}

.vue__time-picker input.display-time{
    margin: 0;
    font-size: 18px;
    width: 100%;
    color: #353535;
    border: none;
    font-weight: 400;
    cursor: pointer;
    z-index: 2;
    background: #0000;
    cursor: pointer;
}
.vue__time-picker input.display-time:focus{
  border:none;
  outline: none;
  cursor: pointer;
}
.vue__time-picker input.display-time::-webkit-input-placeholder,
.vue__time-picker input.display-time::-ms-input-placeholder,
.vue__time-picker input.display-time::placeholder,
.vue__time-picker input.display-time:-ms-input-placeholder {
  color: #bbb;
}
div.error-message-form,div.plan-msg {
    position: absolute;
    background: #de2f2f;
    padding: 4px 8px;
    min-width: 160px;
    font-size: 14px;
    font-weight: 300;
    border-radius: 0;
    color: #fff;
    left: 0px;
    z-index: 10;
    top: 65px;
    box-shadow: 0px 2px 3px -1px #000;
}
.input-group div.error-message-form{
    top: 33px;
    left: 30px;
}
div.error-message-form:before,div.plan-msg:before {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(232, 232, 232, 0);
  border-bottom-color: #de2f2f;
  border-width: 7px;
  margin-left: -7px;
}
.error-message-form .fa-sort-asc {
  top: -9px !important;
  font-size: 27px !important;
  color: #e8e8e8 !important;
  left: 10px !important;
}

/* For IPad  */ 
@media (max-width:990px) and (min-width:768px) {
    .day-hours-div, .day-hours-heading{
        width:25%
    }
    .hours-open-div{
        width: 30%;
    }
    .time-error{
        width: 100%;
        padding: 0;
    }
}
/* For Phone */
@media (max-width:768px) and (min-width:200px) {
    .day-stripe{
        padding: 0;
    }
    .day-hours-div, .day-hours-heading{
        width:100%;
        border-right: none;
    }
    .hours-open-div{
        width: 50%;
    }
    .time-error{
        width: 100%;
        padding: 0;
    }
    .hours-open-div-wrapper{
        padding: 10px;
    }
}

</style>