<template>
    <AppContainer>
        <WeeklySchedule/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import WeeklySchedule from '@/components/dashboard/business/WeeklySchedule.vue'
export default {
  components: {
    AppContainer,
    WeeklySchedule
  }
}
</script>